import { useEffect } from 'react';

import { useSpecificArea } from '@utils/queries/area.queries';

function UserDamageArea({
  areaId,
  map,
}) {
  const { data: area } = useSpecificArea(areaId)

  useEffect(() => {
    if (area) {
      if (!map.getSource('user-area')) {
        map.addSource('user-area', {
          type: 'geojson',
          data: area.feature,
        });
      } else {
        map.getSource('user-area').setData(area.feature);
      }
    }
  }, [map, area]);

  useEffect(() => {
    if (map.getSource('user-area')) {
      map.addLayer({
        id: 'user-area-outer',
        type: 'line',
        source: 'user-area',
        paint: {
          'line-color': '#0367A5',
          'line-width': 2,
          'line-dasharray': [2, 2],
        },
      }, map.getLayer('z-index-5') ? 'z-index-5' : '');
    }

    return () => {
      if (map && !map._removed) {
        if (map.getLayer('user-area-outer')) {
          map.removeLayer('user-area-outer');
        }
  
        if (map.getSource('user-area')) {
          map.removeSource('user-area');
        }
      }
    };
  }, [map, area]);

  return null;
}

export default UserDamageArea;