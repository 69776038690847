import Measurement from './Measurement';
import {
  formatDistance, formatArea, getLength, getArea, 
} from './measurement.utils';
import { getColor } from '../measurement-control.layers';

class MeasurementRectangle extends Measurement {
  constructor(map, featureGroup, options, style) {
    super(map, featureGroup, options, style);
    this._shape = 'rectangle';
    this._hasDistance = true;
    this._hasSurface = true;
  }

  commit() {
    const source = this.map.getSource('measurement-control');
    const feature = source._data.features.pop();

    if (feature) {
      const perimeter = formatDistance(getLength(feature), this.options.unitSystem);
      const surface = formatArea(getArea(feature), this.options.unitSystem);
      
      feature.properties.perimeter = perimeter;
      feature.properties.surface = surface;
  
      const area = getArea(feature) / 10000
      getColor(area, this.map, feature, this.options)
  
      source.setData({ 
        ...source._data, 
        features: [...source._data.features, feature],
      });
    }

    this.shape = null;
  }

  _createFeature(id) {
    return {
      type: 'Feature',
      id,
      geometry: {
        type: 'Polygon',
        coordinates: this.coordinates,
      },
      properties: {
        shape: 'rectangle',
      },
    }
  }

  _addStep(e) {
    let { lngLat } = e;
    lngLat = [lngLat.lng, lngLat.lat];

    if (this.coordinates.length) {
      this.map.fire('measurement-commit', { lngLat: e.lngLat, control: this.control });
      this.map.fire('measurement-end');
    } else {
      this.coordinates = [...this.coordinates, lngLat];
    }
  }

  _render(e) {
    const source = this.map.getSource('measurement-control');
    const feature = source._data.features.pop();

    const startPoint = this.map.project(this.coordinates[0]);
    const rightTopPoint = { x: startPoint.x, y: e.point.y };
    const leftBottomPoint = { x: e.point.x, y: startPoint.y };
    const rightTopLngLat = this.map.unproject(rightTopPoint);
    const leftBottomLngLat = this.map.unproject(leftBottomPoint);
    
    const coords = [
      this.coordinates[0], 
      [leftBottomLngLat.lng, leftBottomLngLat.lat],
      [e.lngLat.lng, e.lngLat.lat],
      [rightTopLngLat.lng, rightTopLngLat.lat],
      this.coordinates[0],
    ];

    feature.geometry.coordinates = [coords];
    source.setData({ ...source._data, features: [...source._data.features, feature] });

    const labelSource = this.map.getSource('measurement-control-labels');
    const labelFeatures = labelSource._data.features.filter((f) => {
      return f.properties.measurementId !== this.measurementId;
    });
    labelSource.setData({ ...labelSource._data, features: labelFeatures });

    this._addAreaPoint(feature);
    this._addMeasureLine([coords[0], coords[1]]);
    this._addMeasureLine([coords[1], coords[2]]);
    this._addMeasureLine([coords[2], coords[3]]);
    this._addMeasureLine([coords[3], coords[4]]);
  }
}

export default MeasurementRectangle;