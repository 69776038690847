/* eslint-disable max-lines */
import { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  faHouseDamage,
  faChevronUp,
  faTrash,
  faChevronDown,
  faPencil,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import { useMutation } from '@tanstack/react-query';
import queryClient from '@utils/queries/QueryClient';
import axios from 'axios';
import {
  Icon,
  Button,
  Toggle,
  Input,
} from '@nazka/nazka.mapframe.components';
import { toast } from 'react-toastify';

import { useFloodDamages } from '@utils/queries/calculation.queries';
import { usePrev } from '@services/hooks.service';
import { useUpdateUserSettingsMutation, useUserAreas } from '@utils/queries/user.queries';
import BuildingDamagesLayer from '@App/components/BuildingDamagesLayer/BuildingDamagesLayer';
import UserDamageArea from '@App/components/UserDamageArea/UserDamageArea';
import {
  setShowBuildingDamages,
  setCurrentSelectedDamageLayer,
  setShowDamageArea,
  setShowDeleteAndEditButtons,
  setSelectedAreaId,
} from '@App/components/LayerSelector/layer-selector.redux.action';
import { setIsCreatingNewDamageLayer } from '@App/components/NewDamageLayer/new-damage-layer.redux.actions';
import { setShowData } from '@templates/T1/t1.redux.actions';
import { fitToUserArea } from '@library/MaplibreMap/maplibre-map.utils';
import Radiogroup from '../CustomRadiogroup/Radiogroup';
import { buildRadioOptions } from './flood-damages-selector.utils';
import CompareModeLayerPanelFloodDamageSection from '../../CompareModeLayerPanel/CompareModeLayerPanelFloodDamageSection';

import './flood-damages-selector.styl';

function FloodDamagesSelector(props) {
  const { map } = props;
  const dispatch = useDispatch();

  const [isExpanded, setIsExpanded] = useState(true);
  const [isEditOn, setIsEditOn] = useState({});
  const [newName, setNewName] = useState(null);

  const { data: floodDamages } = useFloodDamages();
  const { data: userAreas } = useUserAreas();
  const { mutate: updateUserSettings } = useUpdateUserSettingsMutation();

  const selectedValue = useSelector(state => state.layerSelector.currentSelectedDamageLayer);
  const showDamage = useSelector(state => state.layerSelector.showBuildingDamages);
  const previousSelectedValue = usePrev(selectedValue);
  const showArea = useSelector(state => state.layerSelector.showDamageArea);
  const isCompareModeOn = useSelector(state => state.buildingDamages.isComparisonModeOn);
  const exitCompareMode = useSelector(state => state.buildingDamages.exitCompareMode);
  const showDeleteAndEditButtons = useSelector(state => state.layerSelector.showDeleteAndEditButtons);
  const selectedAreaId = useSelector(state => state.layerSelector.selectedAreaId);

  const radio = useMemo(() => buildRadioOptions(floodDamages, userAreas), [floodDamages, userAreas]);

  const dispatchCurrentLayer = (value) => {
    if (value) {
      dispatch(setCurrentSelectedDamageLayer(floodDamages.find(damage => damage.id === value)))
    } else {
      dispatch(setCurrentSelectedDamageLayer(null))
    }
  }
  const radioHandler = (e) => {
    const currentValue = floodDamages.find(damage => damage.id === e)
    const newScenario = currentValue.scenario.split('_')
    if (currentValue.areaId === selectedAreaId) {
      dispatchCurrentLayer(e)
      updateUserSettings({ floodYear: newScenario[0], floodMeasures: newScenario[1] ? `_${newScenario[1]}` : null });
      if (showDamage === false) {
        dispatch(setShowBuildingDamages(true))
      }
    }
  }

  useEffect(() => {
    if (selectedValue) {
      const newScenario = selectedValue.scenario.split('_')
      const currentArea = userAreas.find(x => x.id === selectedValue.areaId)

      if (currentArea) {
        dispatch(setSelectedAreaId(currentArea?.id));
        dispatch(setShowBuildingDamages(true))
        dispatch(setShowDamageArea(true))
        if ((previousSelectedValue && selectedValue.features.length > 0
          && previousSelectedValue.areaId !== selectedValue.areaId)
          || exitCompareMode) {
          fitToUserArea(map, currentArea);
        }
      }

      updateUserSettings({ floodYear: newScenario[0], floodMeasures: newScenario[1] ? `_${newScenario[1]}` : null });
    }
  }, [dispatch, exitCompareMode, isCompareModeOn, map, previousSelectedValue, selectedValue, updateUserSettings, userAreas])

  const hadleToggle = (area) => {
    const newCheckedAreaId = area.id === selectedAreaId ? null : area.id;
    dispatch(setSelectedAreaId(newCheckedAreaId));

    if (newCheckedAreaId) {
      if (radio[area.id]) {
        const floodDamage = floodDamages.find(damage => damage.id === radio[area.id][0].value)
        const newScenario = floodDamage.scenario.split('_')
        updateUserSettings({ floodYear: newScenario[0], floodMeasures: newScenario[1] ? `_${newScenario[1]}` : null });
        dispatchCurrentLayer(radio[area.id][0].value)
        dispatch(setShowBuildingDamages(true))
      } else {
        dispatchCurrentLayer(null)
        dispatch(setShowData(false))
        dispatch(setShowBuildingDamages(false))
      }

      dispatch(setShowDamageArea(true))
      fitToUserArea(map, area);
    } else {
      dispatch(setShowDamageArea(false));
      dispatchCurrentLayer(null);
      dispatch(setShowData(false));
      dispatch(setShowBuildingDamages(false))
    }
  }

  const { mutate: updateAreaName } = useMutation({
    mutationFn: async (areaId) => {
      if (newName) {
        await axios.put(`/api/user/areas/${areaId}`, {
          areaName: newName,
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['user', 'areas'] })
      setNewName(null)
      setIsEditOn({})
    },
    onSuccess: () => {
      toast.success('Successfully updated your area name')
    },
    onError: () => toast.error('Something went wrong updating your area name'),
  });

  const { mutate: removeUserArea } = useMutation({
    mutationFn: async (areaId) => {
      await axios.delete(`/api/user/areas/${areaId}`);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['user', 'areas'] });
    },
    onSuccess: () => {
      toast.success('Successfully deleted your area');
      dispatch(setShowDamageArea(false));
      dispatch(setCurrentSelectedDamageLayer(null));
      dispatch(setShowData(false));
      dispatch(setShowBuildingDamages(false));
    },
    onError: error => toast.error(error.message || 'Something went wrong deleting your flood damages'),
  });

  const [placeholderName, setPlaceholderName] = useState()

  const handleEdit = (e, areaId, areaName) => {
    e.preventDefault()
    setPlaceholderName(areaName)
    setIsEditOn(prevModes => ({
      ...prevModes,
      [areaId]: !prevModes[areaId], // Toggle the edit mode for the specified area ID
    }));
    if (newName) {
      updateAreaName(areaId)
    }
  }

  return (
    <div className="flood-damages-selector">
      <div className="flood-damages-selector__title">
        <Icon icon={faHouseDamage} size="16px" />
        <span>Flood damages</span>
        <div className="flood-damages-selector__title__actions">
          {!isCompareModeOn && (
            <Button
              className="flood-damages-selector__title__actions__button"
              icon={faPlus}
              color="grey"
              layout="ghost"
              onClick={() => {
                dispatch(setIsCreatingNewDamageLayer(true));
              }}
            />
          )}
          {isExpanded && (userAreas.length > 0) && (
            <Button
              className="flood-damages-selector__title__actions__button"
              icon={faPencil}
              layout="ghost"
              color={showDeleteAndEditButtons ? '#0367A5' : 'grey'}
              iconSize="15px"
              onClick={() => dispatch(setShowDeleteAndEditButtons(!showDeleteAndEditButtons))}
            />
          )}
          <Button
            icon={isExpanded ? faChevronUp : faChevronDown}
            color="white"
            hover={false}
            css={{ padding: 0 }}
            onClick={() => setIsExpanded(prevState => !prevState)}
          />
        </div>
      </div>
      {isExpanded ? (
        <div style={{ marginBottom: '9px' }}>
          {isCompareModeOn ? (
            <CompareModeLayerPanelFloodDamageSection />
          ) : (
            <div>
              {userAreas.map(area => (
                <div
                  className={`flood-damages-selector__area__container
                   ${area.id === selectedAreaId ? 'flood-damages-selector__area__container--active' : ''}`}
                >
                  <form onSubmit={handleEdit} className="flood-damages-selector__eacharea">
                    {isEditOn[area.id] ? (
                      <Input
                        onInputChange={(e) => {
                          setNewName(e.target.value)
                          setPlaceholderName(e.target.value)
                        }}
                        rootElProps={{ placeholder: 'New area name' }}
                        css={{
                          border: '1px solid #ddd',
                          borderRadius: '4px',
                          outline: 'none',
                          height: '30px',
                        }}
                        value={placeholderName}
                      />
                    ) : (
                      <Toggle
                        label={area.areaName.length > 30
                          ? `${area.areaName.substring(0, 18)}...`
                          : area.areaName}
                        name={area.id}
                        checked={area.id === selectedAreaId}
                        activeColor="#0367A5"
                        css={{
                          fontSize: '14px',
                          input: { fontSize: '12px' },
                        }}
                        onInputChange={() => hadleToggle(area)}
                        disabled={isCompareModeOn}
                      />
                    )}
                    {showDeleteAndEditButtons && (
                      <div className="flood-damages-selector__eacharea__CTAs">
                        <Button
                          icon={faPencil}
                          onClick={e => handleEdit(e, area.id, area.areaName)}
                          color="white"
                          hover={false}
                          className="layer-selector__delete__button"
                          iconProps={{
                            size: '16px',
                          }}
                          css={{
                            color: 'grey',
                            marginLeft: 0,
                            padding: 0,
                            '&:hover': {
                              color: 'black',
                            },
                            '&:disabled': {
                              backgroundColor: 'transparent',
                              border: 'none',
                              '&:hover': {
                                color: 'grey',
                              },
                            },
                          }}
                          disabled={isCompareModeOn}
                          type="submit"
                        />
                        <Button
                          icon={faTrash}
                          onClick={() => removeUserArea(area.id)}
                          color="white"
                          hover={false}
                          className="layer-selector__delete__button"
                          iconProps={{
                            size: '16px',
                          }}
                          css={{
                            color: 'grey',
                            marginLeft: 0,
                            padding: 0,
                            '&:hover': {
                              color: 'black',
                            },
                            '&:disabled': {
                              backgroundColor: 'transparent',
                              border: 'none',
                              color: 'grey',
                              '&:hover': {
                                color: 'grey',
                              },
                            },
                          }}
                          disabled={isCompareModeOn}
                        />
                      </div>
                    )}
                  </form>
                  <div className="flood-damages-selector__damage__layers__wrapper">
                    {radio[area.id]
                      && (
                        <Radiogroup
                          radios={radio[area.id]}
                          name="sample radio"
                          onChange={e => radioHandler(e)}
                          selectedValue={selectedValue?.id}
                          css={{
                            fontSize: '12px',
                          }}
                          disabled={isCompareModeOn}
                        />
                      )}
                  </div>
                </div>
              ))}
            </div>
          )}
          {(!userAreas || userAreas.length === 0) && (
            <span style={{ fontSize: '13px', fontStyle: 'italic' }}>
              Click on + to create a flood damage layer
            </span>
          )}
        </div>
      ) : null}
      {map && userAreas && showArea && selectedAreaId && (
        <UserDamageArea map={map} areaId={selectedAreaId} />
      )}
      {map && floodDamages && showDamage && selectedValue && (
        <BuildingDamagesLayer map={map} currentSelectedDamageLayer={selectedValue} />
      )}
    </div>
  )
}

export default FloodDamagesSelector;