import React from 'react';
import { createRoot } from 'react-dom/client';
import maplibregl from 'maplibre-gl';
import { Provider } from 'react-redux';
import { QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@nazka/nazka.mapframe.components';

import store from '@App/app.store';
import queryClient from '@utils/queries/QueryClient';
import theme from '@css/theme.style.json';
import { setPopup } from '@library/MaplibreMap/maplibre-map.redux.actions';

import MeasurementPopup from './MeasurementPopup/MeasurementPopup';

const getActiveDrawingLayerStatus = () => {
  const state = store.getState();
  return state.maplibreMap.showActiveDrawingLayer;
};

function onMapClick(e, control, map) {
  if (control.shape && !control.measurement) {
    map.fire('measurement-init', { map, control, lngLat: e.lngLat });
  } else if (control.shape) {
    map.fire('measurement-update', { map, control, lngLat: e.lngLat });
  } else if (!getActiveDrawingLayerStatus() && control.options.displayDialogPopup) {
    const bbox = [
      [e.point.x - 10, e.point.y - 10],
      [e.point.x + 10, e.point.y + 10],
    ];
    const features = map.queryRenderedFeatures(bbox);
    const renderedFeature = features.find(f => f.source === 'measurement-control' && f.id);
    const feature = renderedFeature && map.getSource('measurement-control')._data.features.find(f => f.id === renderedFeature.id);
    if (feature) {
      const popup = new maplibregl.Popup({
        className: 'mapbox-measurement-popup',
        closeButton: false,
      });

      const popupEl = document.createElement('div');
      const root = createRoot(popupEl)

      root.render(
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <MeasurementPopup
                feature={feature}
                popup={popup}
                map={map}
              />
            </ThemeProvider>
          </QueryClientProvider>
        </Provider>,
      );
      popup.setLngLat(e.lngLat).setDOMContent(popupEl).addTo(map);

      store.dispatch(setPopup(popup))

      return popup;
    }
  }
  return null;
}

function onMapDoubleClick(e, control, map) {
  if (control.options.actionsToFinish.includes('dblclick')) {
    map.fire('measurement-commit', { control });
  }
}

function onKeyDown(e, control, map) {
  if (e.keyCode === 27 && control.options.actionsToFinish.includes('esc-key')) {
    map.fire('measurement-commit', { control });
    map.fire('measurement-end');
  }
}

function onMouseMove(e, control, map) {
  if (control.measurement && control.measurement.coordinates.length) {
    control.measurement._render(e);
  }
  if (!control.shape) {
    const bbox = [
      [e.point.x - 10, e.point.y - 10],
      [e.point.x + 10, e.point.y + 10],
    ];
    const features = map.queryRenderedFeatures(bbox);
    const feature = features.find(f => f.source === 'measurement-control' && f.id);

    if (feature) map.getCanvas().classList.add('mapbox-measurement-hover');
    else map.getCanvas().classList.remove('mapbox-measurement-hover');
  }
}

function onTouchStart(e, control, map) {
  if (control.shape && (control.shape === 'rectangle' || control.shape === 'circle') && !control.measurement) {
    map.fire('measurement-init', { map, control, lngLat: e.lngLat });
    map.dragRotate.disable();
    map.dragPan.disable();
  }
}

function onTouchMove(e, control) {
  if (control.measurement && (control.shape === 'rectangle' || control.shape === 'circle')) {
    control.measurement._render(e);
  }
}

function onTouchEnd(e, map, control) {
  if (control.measurement && (control.shape === 'rectangle' || control.shape === 'circle')) {
    map.fire('measurement-commit', { lngLat: e.lngLat, control });
    map.dragRotate.enable();
    map.dragPan.enable();
  }
}

export {
  onMapClick, onMapDoubleClick, onKeyDown, onMouseMove, onTouchStart, onTouchMove, onTouchEnd,
};