function setIsCreatingNewDamageLayer(bool) {
  return {
    type: 'SET_IS_CREATING_NEW_DAMAGE_LAYER',
    payload: bool,
  };
}

function setIsCreatingNewArea(bool) {
  return {
    type: 'SET_IS_CREATING_NEW_AREA',
    payload: bool,
  };
}

export {
  setIsCreatingNewDamageLayer,
  setIsCreatingNewArea,
};