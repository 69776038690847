import React, { useState } from 'react';

import { firstSignIn, signIn } from '@services/auth.service';
import { Checkbox } from '@nazka/nazka.mapframe.components';

import { useAuth } from '../AuthProvider/auth-provider.context';

import './login-modal.styl';

function LoginModal() {
  const { logIn } = useAuth();

  const [remember, setRemember] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
  const [errorToast, setErrorToast] = useState(null)
  const [seconderrorToast, setSecondErrorToast] = useState(null)

  async function confirmLogin() {
    try {
      const signInResult = await signIn({ email, password });
      if (signInResult.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
        setNewPasswordRequired({ email, oldPassword: password, session: signInResult.Session });
      } else {
        logIn(remember);
      }
    } catch (err) {
      setErrorToast(err.response.data.message)
    }
  }

  async function firstLogin() {
    try {
      await firstSignIn({ ...newPasswordRequired, password: newPassword });
      logIn();
    } catch (err) {
      setSecondErrorToast(err.response.data.message)
    }
  }

  return (
    <div className="login-modal">
      <div className="login-modal__window">
        <h2 className="login-modal__window__title">
          {newPasswordRequired ? 'Set password' : 'Login'}
        </h2>
        
        <div className="login-modal__window__form">
          {!newPasswordRequired && (
            <>
              <input
                onChange={e => setEmail(e.target.value)}
                placeholder="Email"
                type="email"
              />
              <input
                value={password}
                onChange={e => setPassword(e.target.value)}
                placeholder="Password"
                type="password"
              />
              <div className="login-modal__window__form__footer">
                <span className="error">{errorToast}</span>
                <Checkbox
                  name="remember-login"
                  text="Remember login"
                  checked={remember}
                  onInputChange={() => setRemember(prevState => !prevState)}
                />
                <div className="login-modal__betaversion">
          
                  {!newPasswordRequired && (
                  <p>
                    This is a beta version. If you would like access, send an email to tijs@nazka.be
                  </p>
                  )}
                </div>
              </div>
              <div className="login-modal__window__submit__button">
                <button
                  type="submit"
                  className="login-modal__window__form__submit"
                  onClick={confirmLogin}
                >
                  <span>Login</span>
                </button>
              </div>
            </>
          )}
          {newPasswordRequired && (
            <>
              <input
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                placeholder="Password"
                type="password"
              />
              <input
                value={newPasswordRepeat}
                onChange={e => setNewPasswordRepeat(e.target.value)}
                placeholder="Repeat password"
                type="password"
              />
              <div className="error__wrapper">
                <span className="error">{seconderrorToast}</span>
              </div>
              <div className="login-modal__window__submit__button">
                <button
                  type="submit"
                  className="login-modal__window__form__submit"
                  onClick={firstLogin}
                >
                  <span>Login</span>
                </button>
              </div>
              
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default LoginModal;