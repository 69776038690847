import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { Button, Input, Loader } from '@nazka/nazka.mapframe.components';
import { faTrash, faPencil } from '@fortawesome/pro-solid-svg-icons';

import { generateUniqueId } from '@services/utils.service'
import { useMutation } from '@tanstack/react-query';
import queryClient from '@utils/queries/QueryClient';
import { toast } from 'react-toastify';

import { useFloodDamages } from '@utils/queries/calculation.queries';
import {
  setCurrentSelectedDamageLayer,
  setShowBuildingDamages,
} from '@App/components/LayerSelector/layer-selector.redux.action';
import { setShowData } from '@templates/T1/t1.redux.actions';

import './radio.styl';

function Radio(props) {
  const {
    label, name, value, checked, onChange, disabled,
  } = props;
  const dispatch = useDispatch()

  const idRef = useRef(generateUniqueId());
  const currentSelectedDamageLayer = useSelector(state => state.layerSelector.currentSelectedDamageLayer)
  const isCompareModeOn = useSelector(state => state.buildingDamages.isComparisonModeOn)
  const showDeleteAndEditButtons = useSelector(state => state.layerSelector.showDeleteAndEditButtons)

  const [isEditOn, setIsEditOn] = useState(false)
  const [newCurrentDamage, setNewCurrentDamage] = useState(null)
  const { data: floodDamages } = useFloodDamages();
  const newValue = floodDamages.find(damage => damage.id === value)
  const [newName, setNewName] = useState(null)
  const [placeholderName, setPlaceholderName] = useState(newValue.description)

  const scenario = newValue.scenario.split('_');

  const { mutate: removeFloodDamages } = useMutation({
    mutationFn: async (damageId) => {
      if (damageId === currentSelectedDamageLayer.id) {
        const damageAreaPool = floodDamages.filter(flood => flood.areaId === currentSelectedDamageLayer.areaId && flood.id !== damageId)
        setNewCurrentDamage(damageAreaPool[0])
      }
      await axios.delete(`/api/calculation/damage/${damageId}`);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['user', 'flood-damages'] })
      if (newCurrentDamage) {
        dispatch(setCurrentSelectedDamageLayer(newCurrentDamage))
      } else {
        dispatch(setCurrentSelectedDamageLayer(null));
        dispatch(setShowData(false));
        dispatch(setShowBuildingDamages(false));
      }
      setNewCurrentDamage(null)
    },
    onSuccess: () => {
      toast.success('Successfully deleted your flood damages')
    },
    onError: error => toast.error(error.message || 'Something went wrong deleting your flood damage'),
  });

  const { mutate: updateDamageLayerName, isPending } = useMutation({
    mutationFn: async () => {
      if (newName) {
        await axios.put(`/api/calculation/damage/${newValue.id}`, {
          description: newName,
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['user', 'flood-damages'] })
      setNewName(null);
    },
    onSuccess: () => {
      setNewName(null)
      toast.success('Successfully updated your flood damage')
    },
    onError: () => toast.error('Something went wrong updating your flood damage'),
  });
  
  const editHandler = (e, mode) => {
    e.preventDefault()
    if (mode === 'edit') {
      setIsEditOn(prevState => !prevState)
    }
    
    if (newName) {
      updateDamageLayerName()
    }
  }

  return (
    <form className="radio" onSubmit={editHandler}>
      <div className="radio__input__label__wrapper">
        <input
          id={idRef.current}
          className="radio__input"
          type="radio"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
        {isPending ? (
          <Loader
            css={{
              height: '40px',
              width: '40px',
            }}
          />
        ) : (
          <label htmlFor={idRef.current} className="radio__label">
            {isEditOn ? (
              <Input 
                onInputChange={(e) => {
                  setNewName(e.target.value)
                  setPlaceholderName(e.target.value)
                }} 
                rootElProps={{ placeholder: 'New damage layer name' }}
                css={{
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  outline: 'none',
                  height: '25px',
                }}
                value={placeholderName}
              />
            ) : (
              <span contentEditable={isEditOn}>
                {label.length > 30
                  ? `${label.substring(0, 18)}...`
                  : label}
              </span>
            )}
          
          </label>
        )}
        <span className="radio__span">
          {scenario[0]}
          -year flood,
          {' '}
          {scenario[1] ? (
            <span>
              {scenario[1] === 'Measures' ? 'anti-flood measures' : 'climate change included'}
            </span>
          ) : (
            <span>no scenario</span>
          )}
        </span>
      </div>
      {!isCompareModeOn && showDeleteAndEditButtons && (
        <div className="flood-damages-selector__eacharea__CTAs">
          <Button 
            icon={faPencil} 
            onClick={e => editHandler(e, 'edit')}
            color="white"
            hover={false}
            className="layer-selector__delete__button"
            iconProps={{
              size: '14px',
            }}
            css={{
              color: 'grey',
              marginLeft: 0,
              padding: 0,
              '&:hover': {
                color: 'black',
              },
              '&:disabled': {
                backgroundColor: 'transparent',
                border: 'none',
                color: 'grey',
                '&:hover': {
                  color: 'grey',
                },
              },
            }}
            disabled={disabled}
          />
          <Button 
            icon={faTrash} 
            onClick={() => removeFloodDamages(value)} 
            color="white"
            hover={false}
            className="layer-selector__delete__button"
            iconProps={{
              size: '14px',
            }}
            css={{
              color: 'grey',
              marginLeft: 0,
              padding: 0,
              '&:hover': {
                color: 'black',
              },
              '&:disabled': {
                backgroundColor: 'transparent',
                border: 'none',
                color: 'grey',
                '&:hover': {
                  color: 'grey',
                },
              },
            }}
            disabled={disabled}
          />
        </div>
      )}
    </form>
  );
}

export default Radio;