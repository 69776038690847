import React from 'react';

import NewDamageLayer from '@App/components/NewDamageLayer/NewDamageLayer';
import ErrorBoundary from '@utils/ErrorBoundary/ErrorBoundary';

import './side-panel.styl';

function SidePanel() {
  return (
    <ErrorBoundary>
      <div className="side-panel">
        <NewDamageLayer />
      </div>
    </ErrorBoundary>
  );
}

export default SidePanel;