import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Input } from '@nazka/nazka.mapframe.components';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import Select from 'react-select';
import axios from 'axios';

import { mainOptions, secondaryOptions } from '@App/components/FloodScenarioModal/flood-scenario-modal.constants';
import { setCurrentSelectedDamageLayer, setSelectedAreaId } from '@App/components/LayerSelector/layer-selector.redux.action';
import { useUpdateUserSettingsMutation, useUserAreas, useUserSettings } from '@utils/queries/user.queries';
import { fitToUserArea } from '@library/MaplibreMap/maplibre-map.utils';
import { setActiveShape, setShowActiveDrawingLayer } from '@library/MaplibreMap/maplibre-map.redux.actions';
import { removeAllFeatures } from '@library/MaplibreMap/controls/Controls/CustomControls/MeasurementControl/Measurement/measurement.utils';
import { usePrev } from '@services/hooks.service';
import queryClient from '@utils/queries/QueryClient';

import { setIsCreatingNewArea, setIsCreatingNewDamageLayer } from './new-damage-layer.redux.actions';

import './new-damage-layer.styl';

function NewDamageLayer() {
  const [name, setName] = useState('');

  const selectedAreaId = useSelector(state => state.layerSelector.selectedAreaId);
  const isCreatingNewArea = useSelector(state => state.newDamageLayer.isCreatingNewArea);
  const map = useSelector(state => state.maplibreMap.map);

  const dispatch = useDispatch();

  const prevSelectedAreaId = usePrev(selectedAreaId);

  const { mutate: updateUserSettings } = useUpdateUserSettingsMutation();
  const { data: userSettings } = useUserSettings();
  const { data: userAreas } = useUserAreas();

  const {
    floodMeasures,
    floodYear,
    isBuildingLayerActive,
  } = userSettings || {};

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async () => {
      const payload = {
        areaId: selectedAreaId,
        model: 'default',
        description: name || 'New damage layer',
        scenario: `${floodYear}${floodMeasures || ''}`,
      };

      return axios.post('/api/calculation/damage', payload).then(result => result.data)
    },
    onSuccess: (createdDamageLayer) => {
      dispatch(setShowActiveDrawingLayer(true));
      toast.success('Damage layer created');
      const previousDamageLayers = queryClient.getQueryData(['user', 'flood-layers']) || [];
      queryClient.setQueryData(['user', 'flood-damages'], [createdDamageLayer, ...previousDamageLayers]);
      dispatch(setCurrentSelectedDamageLayer(createdDamageLayer));
      dispatch(setIsCreatingNewDamageLayer(false));
    },
  });

  useEffect(() => {
    return () => {
      if (map) {
        map.fire('measurement-end');
        removeAllFeatures(map);
      }
    }
  }, [map]);

  useEffect(() => {
    if (selectedAreaId && selectedAreaId !== prevSelectedAreaId) {
      const userArea = userAreas.find(uA => uA.id === selectedAreaId);
      map.getSource('measurement-control').setData({ type: 'FeatureCollection', features: [userArea.feature] });

      setTimeout(() => {
        fitToUserArea(map, userArea);
      })
    }
  }, [selectedAreaId, map, userAreas, prevSelectedAreaId]);

  const userAreaOptions = userAreas?.map(userArea => ({ value: userArea.id, label: userArea.areaName }));
  const userAreaValue = userAreaOptions?.find(o => o.value === selectedAreaId);

  return (
    <div className="new-damage-layer">
      <h2 className="new-damage-layer__title">
        New damage layer
      </h2>
      <div className="new-damage-layer__content">
        <label htmlFor="damage-layer-name" className="new-damage-layer__content__name__label">
          Damage layer name:
        </label>
        <Input
          value={name}
          onInputChange={e => setName(e.target.value)}
          rootElProps={{
            id: 'damage-layer-name',
            placeholder: 'Damage layer name...',
          }}
          css={{
            '&:focus-visible': {
              outline: '1px solid currentColor',
            },
          }}
          disabled={isCreatingNewArea}
        />
        <p>
          Select your flood scenario:
        </p>
        <div className="new-damage-layer__content__scenario">
          <Select
            className="new-damage-layer__content__scenario__main"
            options={mainOptions}
            value={mainOptions.find(o => o.value === floodYear) || mainOptions[0]}
            onChange={(selectedOption) => {
              updateUserSettings({ floodYear: selectedOption?.value });
            }}
            isDisabled={isCreatingNewArea}
          />
          <Select
            className="new-damage-layer__content__scenario__secondary"
            options={secondaryOptions}
            value={secondaryOptions.find(o => o.value === floodMeasures) || secondaryOptions[0]}
            onChange={(selectedOption) => {
              updateUserSettings({ floodMeasures: selectedOption?.value });
            }}
            isDisabled={isCreatingNewArea}
          />
        </div>
        <p>
          Select your land use features:
        </p>
        <div className="new-damage-layer__content__land-use-features">
          <Checkbox
            checked={isBuildingLayerActive}
            onInputChange={(e) => {
              updateUserSettings({ isBuildingLayerActive: e.target.checked });
            }}
            text="Buildings"
            disabled={isCreatingNewArea}
          />
        </div>
        <p>
          Select your area of interest:
        </p>
        <div className="new-damage-layer__content__area">
          <Button
            className="new-damage-layer__content__area__button"
            onClick={() => {
              const newIsCreatingNewArea = !isCreatingNewArea;
              removeAllFeatures(map);

              if (newIsCreatingNewArea) {
                dispatch(setActiveShape('circle'));
                dispatch(setSelectedAreaId(null));
                map.fire('measurement-start', { shape: 'circle' });
              } else {
                map.fire('measurement-end');
              }

              dispatch(setIsCreatingNewArea(newIsCreatingNewArea));
            }}
            icon={isCreatingNewArea ? null : faPencil}
            css={{ flexDirection: 'row-reverse' }}
          >
            {isCreatingNewArea ? 'Cancel drawing' : 'Draw new area'}
          </Button>
          {!isCreatingNewArea && userAreas?.length > 0 && (
            <Select
              className="new-damage-layer__content__area__select"
              options={userAreaOptions}
              onChange={(selectedOption) => {
                dispatch(setSelectedAreaId(selectedOption.value));
              }}
              value={userAreaValue}
              isDisabled={isCreatingNewArea}
            />
          )}
        </div>
      </div>
      {isError && (
        <span className="new-damage-layer__error">
          Something went wrong creating the damage layer. If this error persists, please contact support.
        </span>
      )}
      <div className="new-damage-layer__actions">
        <Button
          disabled={isCreatingNewArea || !isBuildingLayerActive || !selectedAreaId || !name}
          onClick={mutate}
          isLoading={isPending}
        >
          Calculate
        </Button>
        <Button
          layout="outlined"
          onClick={() => {
            dispatch(setIsCreatingNewDamageLayer(false));
            dispatch(setIsCreatingNewArea(false));
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default NewDamageLayer;