import React from 'react';
import { createRoot } from 'react-dom/client';
import store from '@App/app.store';
import { Provider } from 'react-redux';

import { ThemeProvider } from '@nazka/nazka.mapframe.components';
import { setActiveShape } from '@library/MaplibreMap/maplibre-map.redux.actions';
import MeasurementSelectType from './MeasurementSelectType/MeasurementSelectType';
import {
  onMeasurementInit,
  onMeasurementUpdate,
  onMeasurementCommit,
} from './measurement-control.utils';
import {
  onMapClick,
  onMapDoubleClick,
  onKeyDown,
  onMouseMove,
  onTouchStart,
  onTouchMove,
  onTouchEnd,
} from './measurement-control.events';
import { addSourcesAndLayers, removeSourcesAndLayers } from './measurement-control.layers';

class MeasurementControl {
  constructor({
    position = 'topright',
    collapsed = true,
    shapes = ['line', 'rectangle', 'polygon', 'circle'],
    unitSystem = 'metric',
    actionsToFinish = ['click-first', 'click-last', 'dblclick', 'esc-key'],
    displayDialogPopup = true,
    displayLength = true,
    displayArea = true,
    displayCircleRadius = true,
    helperDots,
    lineColor = '#000',
    fillColor = 'rgba(0, 0, 0, 0.15)',
    helperDotColor = '#555',
  }) {
    this.container = null;
    this.shape = null;
    this.measurement = null;
    this.popup = null;
    this.map = null;

    this.options = {
      position,
      collapsed,
      shapes,
      unitSystem,
      displayDialogPopup,
      displayLength,
      displayArea,
      displayCircleRadius,
      actionsToFinish,
      helperDots,
      lineColor,
      fillColor,
      helperDotColor,
    };

    this.onMeasurementStart = this.onMeasurementStart.bind(this);
    this.onMeasurementEnd = this.onMeasurementEnd.bind(this);
  }

  onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group mapboxgl-ctrl-measure';
    this.createSourcesAndLayers = () => addSourcesAndLayers(map, this.options);
    this.removeSourcesAndLayers = () => removeSourcesAndLayers(map, this.options);

    this.onMapClick = e => onMapClick(e, this, map);
    this.onMapDoubleClick = e => onMapDoubleClick(e, this, map);
    this.onKeyDown = e => onKeyDown(e, this, map);
    this.onMouseMove = e => onMouseMove(e, this, map);
    this.onTouchStart = e => onTouchStart(e, this, map);
    this.onTouchMove = e => onTouchMove(e, this, map);
    this.onTouchEnd = e => onTouchEnd(e, map, this);

    const root = createRoot(this.container);
    root.render(
      <ThemeProvider>
        <Provider store={store}>
          <MeasurementSelectType
            map={map}
            parent={this}
            options={this.options}
          />
        </Provider>
      </ThemeProvider>
      ,
    )

    map.on('click', this.onMapClick);
    map.on('dblclick', this.onMapDoubleClick);
    map.on('mousemove', this.onMouseMove);
    map.on('touchstart', this.onTouchStart);
    map.on('touchmove', this.onTouchMove);
    map.on('touchend', this.onTouchEnd);
    map.on('load', this.createSourcesAndLayers);
    document.addEventListener('keydown', this.onKeyDown);

    map.on('measurement-start', this.onMeasurementStart);
    map.on('measurement-init', onMeasurementInit);
    map.on('measurement-update', onMeasurementUpdate);
    map.on('measurement-commit', onMeasurementCommit);
    map.on('measurement-end', this.onMeasurementEnd);

    return this.container;
  }

  onRemove(map) {
    this.container.parentNode.removeChild(this.container);
    this.removeSourcesAndLayers();

    map.off('click', this.onMapClick);
    map.off('dblclick', this.onMapDoubleClick);
    map.off('mousemove', this.onMouseMove);
    map.off('touchstart', this.onTouchStart);
    map.off('touchmove', this.onTouchMove);
    map.off('touchend', this.onTouchEnd);
    map.off('load', this.createSourcesAndLayers);
    document.removeEventListener('keydown', this.onKeyDown);

    map.off('measurement-start', this.onMeasurementStart);
    map.off('measurement-init', onMeasurementInit);
    map.off('measurement-update', onMeasurementUpdate);
    map.off('measurement-commit', onMeasurementCommit);
    map.off('measurement-end', this.onMeasurementEnd);

    if (this.popup) this.popup.remove();
  }

  onMeasurementStart({ shape }) {
    this.shape = shape;

    this.map.getCanvas().style.cursor = 'crosshair';
    this.map.setMinZoom(15);
  }

  onMeasurementEnd() {
    this.map.setMinZoom(8);
    store.dispatch(setActiveShape(null));
  
    if (this.measurement) {
      this.measurement.commit();
      this.measurement = null;
    }
  
    this.shape = null;
  
    this.map.getCanvas().style.cursor = '';
  
    if (this.clickZoomWasEnabled) {
      this.map.doubleClickZoom.enable();
    }
  }
}

export default MeasurementControl;