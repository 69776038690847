const defaultState = {
  isCreatingNewDamageLayer: false,
  isCreatingNewArea: false,
};

function newDamageLayerPanel(state = defaultState, action) {
  switch (action.type) {
    case 'SET_IS_CREATING_NEW_DAMAGE_LAYER':
      return {
        ...state,
        isCreatingNewDamageLayer: action.payload,
      };
    case 'SET_IS_CREATING_NEW_AREA':
      return {
        ...state,
        isCreatingNewArea: action.payload,
      };
    default:
      return state;
  }
}

export default newDamageLayerPanel;