import circle from '@turf/circle';
import {
  formatDistance, formatArea, getLength, getArea, 
} from './measurement.utils';

import Measurement from './Measurement';
import { getColor } from '../measurement-control.layers';

class MeasurementCircle extends Measurement {
  constructor(map, featureGroup, options, style) {
    super(map, featureGroup, options, style);
    this._shape = 'circle';
    this._hasDistance = true;
    this._hasSurface = true;
  }

  commit() {
    const source = this.map.getSource('measurement-control');
    const feature = source._data.features.find(f => f.id === this.measurementId);
    const features = [...source._data.features].filter(f => f.id !== this.measurementId);

    if (feature) {
      const surface = formatArea(getArea(feature), this.options.unitSystem);
      feature.properties.surface = surface;
  
      const area = getArea(feature) / 10000
      getColor(area, this.map, feature, this.options)
      
      if (this.options.displayCircleRadius) {
        const radiusFeature = source._data.features.find(f => f.properties.measurementId === `${this.measurementId}_radius`);
        const radius = formatDistance(getLength(radiusFeature), this.options.unitSystem);
        feature.properties.radius = radius;
      }
  
      source.setData({ 
        ...source._data, 
        features: [...features, feature],
      });
    }

    this.shape = null;
  }

  _createFeature(id) {
    return {
      type: 'Feature',
      id,
      geometry: {
        type: 'Polygon',
        coordinates: this.coordinates,
      },
      properties: {
        shape: 'circle',
      },
    }
  }

  _addStep(e) {
    let { lngLat } = e;
    lngLat = [lngLat.lng, lngLat.lat];

    if (this.coordinates.length) {
      this.map.fire('measurement-commit', { lngLat: e.lngLat, control: this.control });
      this.map.fire('measurement-end');
    } else {
      this.center = lngLat;
      this.coordinates = [this.center];
    }
  }

  _render(e) {
    const source = this.map.getSource('measurement-control');
    const feature = source._data.features.find(f => f.id === this.measurementId);
    const features = [...source._data.features].filter(f => f.id !== this.measurementId);

    const radius = getLength({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [this.center, [e.lngLat.lng, e.lngLat.lat]],
      },
    });
    const options = { steps: 50, units: 'kilometers' };
    const circleFeature = circle(this.center, (radius / 1000), options);
    feature.geometry.coordinates = circleFeature.geometry.coordinates;
    source.setData({ ...source._data, features: [...features, feature] });

    const labelSource = this.map.getSource('measurement-control-labels');
    const labelFeatures = labelSource._data.features.filter((f) => {
      return f.properties.measurementId !== this.measurementId;
    });
    labelSource.setData({ ...labelSource._data, features: labelFeatures });

    this._addAreaPoint(feature);
    
    if (this.options.displayCircleRadius) {
      this._addRadiusLine([this.center, [e.lngLat.lng, e.lngLat.lat]]);
      this._addMeasureLine([this.center, [e.lngLat.lng, e.lngLat.lat]]);
    }
  }

  _addRadiusLine(coordinates) {
    const source = this.map.getSource('measurement-control');
    const features = [...source._data.features].filter((f) => {
      return f.properties.measurementId !== `${this.measurementId}_radius`;
    });
    const feature = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates,
      },
      properties: {
        measurementId: `${this.measurementId}_radius`,
        geometryType: 'LineString',
      },
    }
    source.setData({ ...source._data, features: [...features, feature] });
  }
}

export default MeasurementCircle;