import MeasurementPolyline from './Measurement/MeasurementPolyline';
import MeasurementPolygon from './Measurement/MeasurementPolygon';
import MeasurementRectangle from './Measurement/MeasurementRectangle';
import MeasurementCircle from './Measurement/MeasurementCircle';

function onMeasurementInit(e) {
  const { map, control, lngLat } = e;

  switch (control.shape) {
    case 'polyline':
      control.measurement = new MeasurementPolyline(map, control);
      break;
    case 'rectangle':
      control.measurement = new MeasurementRectangle(map, control);
      break;
    case 'polygon':
      control.measurement = new MeasurementPolygon(map, control);
      break;
    case 'circle':
      control.measurement = new MeasurementCircle(map, control);
      break;
    default: 
      control.measurement = new MeasurementPolyline(map, control);
      break;
  }

  control.measurement.start({ lngLat });
  if (map.doubleClickZoom.isEnabled()) {
    this.clickZoomWasEnabled = true;
  }
  map.doubleClickZoom.disable();
}

function onMeasurementUpdate(e) {
  const { control, lngLat } = e;
  control.measurement._addStep({ lngLat });
}

function onMeasurementCommit(e) {
  const { control, lngLat } = e;

  if (control.measurement) {
    control.measurement.commit();
    control.measurement._addMeasurementPopupToMap(lngLat);
    control.measurement = null;
  }
}

export { 
  onMeasurementInit,
  onMeasurementUpdate,
  onMeasurementCommit,
};